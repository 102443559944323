import React, { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import {
  Building,
  Building2,
  ChevronRight,
  HelpCircle,
  ProportionsIcon,
  Receipt,
  X,
} from "lucide-react";
import dayjs from "dayjs";
import DatePicker from "@/components/ui/date-input";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { AutoComplete } from "@/components/ui/autocomplete";
import {
  AICommandPredictionSchema,
  ListOfReportsSchema,
} from "@/components/command-modal/models";
import { httpClient } from "@/httpClient";
import { useDebounce } from "@uidotdev/usehooks";
import { toast } from "@/components/ui/use-toast";
import intent_url_mapper from "@/utils/intent_mapper";
import { DrawerContent, DrawerClose, Drawer } from "@/components/ui/drawer";
import { Route as invoicingRoute } from "@/routes/invoicing/index";

interface ReportAnchorProps {
  title: string;
  link: string;
  onClick?: () => void;
}
export type Option = Record<"value" | "label", string> & Record<string, string>;
interface DatePickerState {
  type: "single" | "range" | null;
  startDate?: Date;
  endDate?: Date;
  selectedDate?: Date;
  link: string | null;
}

const ReportAnchor: React.FC<ReportAnchorProps> = ({
  title,
  link,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="text-sm bg-[#F4F4F5] rounded-lg cursor-pointer"
      onClick={handleClick}
    >
      <div className="px-6 py-3 flex items-center justify-between">
        <p>{title}</p>
        <ChevronRight size={18} />
      </div>
    </div>
  );
};

const ViewReports = () => {
  const navigate = useNavigate();
  const props = invoicingRoute.useLoaderData();
  const [datePickerState, setDatePickerState] = useState<DatePickerState>({
    type: null,
    link: null,
    startDate: dayjs().add(-1, "months").toDate(),
    endDate: dayjs().toDate(),
    selectedDate: dayjs().toDate(),
  });
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [helpDrawerVisible, setHelpDrawerVisible] = useState(false); // State for help drawer
  const [searchReport, setSearchReport] = useState<string>("");
  const [selectedReport, setSelectedReport] = useState<Option | undefined>();
  const [reports, setReports] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const debouncedSearch = useDebounce(searchReport, 500);

  const handleApplyDates = () => {
    const { type, startDate, endDate, selectedDate, link } = datePickerState;

    if (type === "range" && startDate && endDate) {
      setDatePickerVisible(false);
      navigate({
        to: `${link}?startDate=${dayjs(startDate).format("YYYY-MM-DD")}&endDate=${dayjs(endDate).format("YYYY-MM-DD")}`,
      });
    } else if (type === "single" && selectedDate) {
      setDatePickerVisible(false);
      navigate({
        to: `${link}?date=${dayjs(selectedDate).format("YYYY-MM-DD")}`,
      });
    }
  };

  const handleReportAnchorClick = (
    link: string,
    requires_end_date: boolean,
    requires_start_date: boolean,
    detected_start_date?: string,
    detected_end_date?: string
  ) => {
    console.log(link, requires_end_date, requires_start_date);
    if (requires_end_date || requires_start_date) {
      let type: "single" | "range" | null = null;
      if (requires_end_date && requires_start_date) {
        type = "range";
        if (
          detected_end_date &&
          detected_start_date &&
          detected_start_date !== "" &&
          detected_end_date !== ""
        ) {
          navigate({
            to: `${link}?startDate=${detected_start_date}&endDate=${detected_end_date}`,
          });
          return;
        }
      } else if (requires_start_date && !requires_end_date) {
        type = "single";
        if (detected_start_date && detected_start_date !== "") {
          navigate({
            to: `${link}?date=${detected_start_date}`,
          });
          return;
        }
      }
      setDatePickerState({
        type,
        link,
        startDate: dayjs().add(-1, "months").toDate(),
        endDate: dayjs().toDate(),
        selectedDate: dayjs().toDate(),
      });
      setDatePickerVisible(true);
    } else {
      navigate({ to: link });
    }
  };

  const handleValueChange = (value: Option | null) => {
    if (value) {
      setSelectedReport(value);
      handleReportAnchorClick(
        value.url,
        value.requires_end_date === "true",
        value.requires_start_date === "true",
        value.start_date,
        value.end_date
      );
    } else {
      setSelectedReport(undefined);
    }
  };

  useEffect(() => {
    console.log(`${import.meta.env.VITE_AI_API_URL as string}`);
    const getAIPredictions = async () => {
      try {
        setIsLoading(true);
        const response = await httpClient.post(
          `${import.meta.env.VITE_AI_API_URL as string}/nlu/predict`,
          {
            text: debouncedSearch,
          }
        );
        const finalData = response.data as AICommandPredictionSchema;
        if (finalData.intent !== "fallback_intent") {
          const url = intent_url_mapper[finalData.intent];
          let label = finalData.config.display_name;
          if (
            finalData.config.requires_start_date &&
            finalData.config.requires_end_date
          ) {
            if (finalData.entities.start_date && finalData.entities.end_date) {
              label += ` for ${dayjs(finalData.entities.start_date).format("DD MMM YYYY")} to ${dayjs(finalData.entities.end_date).format("DD MMM YYYY")}`;
            }
          } else if (
            finalData.config.requires_start_date &&
            !finalData.config.requires_end_date
          ) {
            if (finalData.entities.start_date) {
              label += ` for ${dayjs(finalData.entities.start_date).format("DD MMM YYYY")}`;
            }
          }
          const report: Option = {
            label: label,
            value: finalData.intent,
            url: url,
            requires_end_date: finalData.config.requires_end_date
              ? "true"
              : "false",
            requires_start_date: finalData.config.requires_start_date
              ? "true"
              : "false",
            start_date: finalData.entities.start_date
              ? dayjs(finalData.entities.start_date).format("YYYY-MM-DD")
              : "",
            end_date: finalData.entities.end_date
              ? dayjs(finalData.entities.end_date).format("YYYY-MM-DD")
              : "",
          };
          setReports([report]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        toast({
          title: "Error",
          description: "An error occurred while fetching reports",
          duration: 5000,
        });
      }
    };
    if (debouncedSearch?.length > 3) {
      getAIPredictions();
    } else {
      setReports([]);
    }
  }, [debouncedSearch]);

  const renderDatePicker = () => {
    const { type, startDate, endDate, selectedDate } = datePickerState;

    return (
      <Dialog open={datePickerVisible}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {type === "range"
                ? "Select Date Range"
                : type === "single"
                  ? "Select Date"
                  : ""}
            </DialogTitle>
          </DialogHeader>
          {type === "range" && (
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Start Date
                </Label>
                <DatePicker
                  selected={startDate}
                  onSelect={(date) =>
                    setDatePickerState({
                      ...datePickerState,
                      startDate: date,
                    })
                  }
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="username" className="text-right">
                  End Date
                </Label>
                <DatePicker
                  selected={endDate}
                  onSelect={(date) =>
                    setDatePickerState({
                      ...datePickerState,
                      endDate: date,
                    })
                  }
                />
              </div>
            </div>
          )}
          {type === "single" && (
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Start Date
                </Label>
                <DatePicker
                  selected={selectedDate}
                  onSelect={(date) =>
                    setDatePickerState({
                      ...datePickerState,
                      selectedDate: date,
                    })
                  }
                />
              </div>
            </div>
          )}
          <DialogFooter>
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleApplyDates}
            >
              Apply
            </button>
            <button
              type="button"
              className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                setDatePickerVisible(false);
                setDatePickerState({ type: null, link: null });
              }}
            >
              Cancel
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const renderHelpDrawer = () => (
    <Drawer
      open={helpDrawerVisible}
      onOpenChange={setHelpDrawerVisible}
      direction="right"
    >
      <DrawerContent className="focus-visible:outline-none w-fit min-w-[30vw] p-0">
        <DrawerClose asChild>
          <Button className="fixed inset-y-2 right-4 bg-white mt-4 hover:bg-white">
            <X className="h-5 w-5 text-gray-500" />
          </Button>
        </DrawerClose>
        <div className="pt-4">
          <h2 className="text-2xl font-bold text-wrap mb-8 mt-3 ml-4">
            Dynamic Reports Generation
          </h2>

          <div className="mt-">
            {props.reportsList ? (
              props.reportsList.map((report, index) => (
                <div
                  key={index}
                  className="w-full py-2 border-b border-gray-300 flex items-center"
                >
                  <ProportionsIcon className="h-4 w-4 mx-4 " />
                  <div className="flex-col">
                    <p className="text-sm text-gray-700 font-light">
                      {report.display_name}
                    </p>
                    <p className="text-xs text-gray-500 font-extralight">
                      {report.description}
                    </p>
                  </div>
                  <ChevronRight className="h-4 w-4 ml-auto mr-4" />
                </div>
              ))
            ) : (
              <p className="text-gray-500">Loading reports...</p>
            )}
            {props.reportsList && props.reportsList.length === 0 && (
              <p className="text-gray-500">No reports available.</p>
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );

  return (
    <div className="bg-gray-900 p-4 h-[500px] flex flex-col">
      <div className="flex flex-col flex-grow items-center justify-center mb-6">
        <p className="text-white text-3xl font-bold w-1/2 text-left">
          Search for Reports
        </p>
        <div className="mt-6 mb-4 w-1/2 flex relative">
          <AutoComplete
            options={reports}
            emptyMessage={
              searchReport?.length > 3
                ? "No reports found"
                : "Start typing to search for reports"
            }
            placeholder="Search for reports"
            onValueChange={handleValueChange}
            value={selectedReport}
            input={searchReport}
            onInputChanged={setSearchReport}
            shouldFilter={false}
            isLoading={isLoading}
          />
          <Button
            variant="outline"
            onClick={() => setHelpDrawerVisible(true)} // Show help drawer
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white border-none h-8 p-2"
          >
            <HelpCircle className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <p className="text-white text-sm font-semibold mt-6 mb-4">
        {" "}
        Quick Report Access
      </p>
      <div className="overflow-auto">
        <div className="grid grid-cols-3 gap-x-4 gap-y-3">
          <ReportAnchor
            title="Expiry List"
            link="/reports/expiry-list/"
            onClick={() =>
              handleReportAnchorClick("/reports/expiry-list/", false, false)
            }
          />
          <ReportAnchor
            title="Void Sales"
            link=""
            onClick={() =>
              handleReportAnchorClick("/reports/void-sales", false, false)
            }
          />
          <ReportAnchor
            title="Profit & Loss"
            link="/reports/pl-report"
            onClick={() =>
              handleReportAnchorClick("/reports/pl-report", true, true)
            }
          />
          <ReportAnchor
            title="Stock Summary"
            link="/inventory/available-inventory"
            onClick={() =>
              handleReportAnchorClick(
                "/inventory/available-inventory",
                false,
                false
              )
            }
          />
          <ReportAnchor
            title="Supplier Wise Purchase"
            link="/reports/purchase-invoice-list/"
            onClick={() =>
              handleReportAnchorClick(
                "/reports/purchase-invoice-list/?open_supplier_picker=true",
                false,
                false
              )
            }
          />
        </div>
      </div>

      {datePickerVisible && renderDatePicker()}
      {helpDrawerVisible && renderHelpDrawer()}
    </div>
  );
};

export default ViewReports;
