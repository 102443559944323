import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Route as PurchaseInvoiceRoute } from "@/routes/reports/purchase-invoice-list/index";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { PurchaseInvoiceManySchema, PurchaseInvoiceSingleSchema } from "./type";
import normalizeEnums from "@/utils/normalize_enums";
import { httpClient } from "@/httpClient";
import { LoadState } from "@/utils/load_state";
import { CircleXIcon, X } from "lucide-react";
import { Loading } from "@/layouts/loading";
import { pdf } from "@react-pdf/renderer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import InvoicePDF from "./components/invoice-pdf";
import { AutoComplete, Option } from "@/components/ui/autocomplete";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const apiUrl = import.meta.env.VITE_API_URL as string;

const DrawerDataRenderer = (props: {
  simpleDrawerData: PurchaseInvoiceManySchema;
}) => {
  const { simpleDrawerData } = props;
  const [pharmacyName, setPharmacyName] = useState<string>("");
  const [drawerData, setDrawerData] = useState<
    PurchaseInvoiceSingleSchema | undefined
  >();
  const [drawerLoading, setDrawerLoading] = useState(LoadState.Loading);

  const getInvoice = async (invoiceId: string) => {
    setDrawerLoading(LoadState.Loading);
    try {
      const data = await httpClient.post(
        `${apiUrl}/public/pharmacy/purchase_invoice/get/one`,
        {
          id: invoiceId,
        }
      );
      const pharmacyData = await httpClient.post(`${apiUrl}/public/pharmacy`, {
        pharmacy_code: simpleDrawerData.pharmacy_code,
      });
      setPharmacyName(pharmacyData.data.data.metadata.pharmacy_name);
      setDrawerLoading(LoadState.Loaded);
      setDrawerData(data.data.data as PurchaseInvoiceSingleSchema);
    } catch (e) {
      console.error("Error fetching purchase invoice:", e);
      setDrawerLoading(LoadState.Error);
    }
  };

  const Title = (props: PropsWithChildren) => {
    return (
      <div className="text-md font-normal text-[#848484]">{props.children}</div>
    );
  };
  const Content = (props: PropsWithChildren) => {
    return (
      <div className="text-md font-normal text-black ">{props.children}</div>
    );
  };
  const Cell = (props: PropsWithChildren) => {
    return (
      <div className="flex flex-col gap-1 items-start justify-center">
        {props.children}
      </div>
    );
  };
  const Row = (props: PropsWithChildren) => {
    return (
      <div className="grid grid-cols-2 gap-6 py-1 items-start justify-start border-b border-[#D9D9D9] px-4">
        {props.children}
      </div>
    );
  };

  useEffect(() => {
    getInvoice(simpleDrawerData.id);
  }, [simpleDrawerData]);

  return (
    <DrawerContent className="focus-visible:outline-none w-fit min-w-[60vw] font-lexend">
      <DrawerClose asChild className="fixed inset-y-2 right-4">
        <Button className="h-fit aspect-square p-2 grid place-items-center bg-[#eceff8] hover:bg-[#eceff8]">
          <X className="text-[#667085] h-6 w-6"></X>
        </Button>
      </DrawerClose>

      {drawerLoading === LoadState.Loading && <Loading></Loading>}
      {drawerLoading === LoadState.Error && <div>Failed to load data</div>}
      {drawerLoading === LoadState.Loaded && drawerData && (
        <div className="grid grid-cols-[6fr_25vw] h-full w-full">
          <div className="flex flex-col gap-4 border-r border-r-[#D9D9D9] px-2">
            <DrawerHeader className="flex flex-row justify-between w-full">
              <DrawerTitle>Inventory Details</DrawerTitle>
              <div className="text-[#848484] text-lg  font-normal">
                Total Stock :{" "}
                <span className="text-[#1458DD]">
                  {drawerData.medical_items.length +
                    drawerData.non_medical_items.length}
                </span>
              </div>
            </DrawerHeader>
            <div className="h-full relative mb-4">
              <Tabs defaultValue="medical" className="">
                <div className="w-full grid place-items-center">
                  <TabsList>
                    <TabsTrigger value="medical">Medical</TabsTrigger>
                    <TabsTrigger value="non-medical">Non-Medical</TabsTrigger>
                  </TabsList>
                </div>
                <TabsContent value="medical">
                  {drawerData.medical_items.length === 0 ? (
                    <div className="flex items-center justify-center h-full mt-10">
                      <div className="text-[#848484] text-lg font-normal">
                        No Medical Items Found
                      </div>
                    </div>
                  ) : (
                    <div className="max-h-[70vh] overflow-y-auto">
                      <Table className="text-sm">
                        <TableHeader>
                          <TableRow className="hover:bg-inherit">
                            <TableHead>SL No.</TableHead>
                            <TableHead>Product Name</TableHead>
                            <TableHead>Quantity</TableHead>
                            <TableHead>VAT</TableHead>
                            <TableHead>Unit Price</TableHead>
                            <TableHead>Total Price</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody className="">
                          {drawerData.medical_items.map((item, idx) => {
                            return (
                              <TableRow
                                key={idx}
                                className="font-normal cursor-pointer"
                              >
                                <TableCell className="text-center">
                                  {idx + 1}
                                </TableCell>
                                <TableCell>
                                  {item.trade_name} | {item.batch_number}
                                </TableCell>
                                <TableCell className="text-center">
                                  {item.quantity.original}
                                </TableCell>
                                <TableCell className="text-center">
                                  {item.vat} %
                                </TableCell>
                                <TableCell className="text-center">
                                  AED {item.cost}
                                </TableCell>
                                <TableCell className="text-center">
                                  AED{" "}
                                  {(1 + item.vat / 100) *
                                    item.cost *
                                    item.quantity.original}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </TabsContent>
                <TabsContent value="non-medical">
                  {drawerData.non_medical_items.length === 0 ? (
                    <div className="flex items-center justify-center h-full mt-10">
                      <div className="text-[#848484] text-lg font-normal">
                        No Non-Medical Items Found
                      </div>
                    </div>
                  ) : (
                    <div className="max-h-[70vh] overflow-y-auto">
                      <Table className="text-sm">
                        <TableHeader>
                          <TableRow className="hover:bg-inherit">
                            <TableHead>SL No.</TableHead>
                            <TableHead>Product Name</TableHead>
                            <TableHead>Quantity</TableHead>
                            <TableHead>VAT</TableHead>
                            <TableHead>Unit Price</TableHead>
                            <TableHead>Total Price</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody className="">
                          {drawerData.non_medical_items.map((item, idx) => {
                            return (
                              <TableRow
                                key={idx}
                                className="font-normal cursor-pointer"
                              >
                                <TableCell className="text-center">
                                  {idx + 1}
                                </TableCell>
                                <TableCell>
                                  {item.trade_name} | {item.batch_number}
                                </TableCell>
                                <TableCell className="text-center">
                                  {item.quantity.original}
                                </TableCell>
                                <TableCell className="text-center">
                                  {item.vat} %
                                </TableCell>
                                <TableCell className="text-center">
                                  AED {item.cost}
                                </TableCell>
                                <TableCell className="text-center">
                                  AED{" "}
                                  {(1 + item.vat / 100) *
                                    item.cost *
                                    item.quantity.original}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </TabsContent>
              </Tabs>
              <div className="w-full absolute grid place-items-center bottom-0">
                <Button
                  onClick={() => {
                    const pdfDoc = (
                      <InvoicePDF
                        medicalItems={drawerData.medical_items.map((item) => {
                          return {
                            trade_name: item.trade_name,
                            cost: item.cost,
                            quantity: item.quantity.original,
                            batch_number: item.batch_number,
                            vat: item.vat,
                          };
                        })}
                        nonMedicalItems={drawerData.non_medical_items.map(
                          (item) => {
                            return {
                              trade_name: item.trade_name,
                              cost: item.cost,
                              quantity: item.quantity.original,
                              batch_number: item.batch_number,
                              vat: item.vat,
                            };
                          }
                        )}
                        invoiceDetails={{
                          delivery_date: simpleDrawerData.delivery_date,
                          invoice_number: simpleDrawerData.invoice_number,
                          payment_mode: simpleDrawerData.payment_mode,
                          payment_type: simpleDrawerData.payment_type,
                          supplier_name: simpleDrawerData.supplier_name,
                          calculated_payment_date: simpleDrawerData
                            .calculated_payment_date.Valid
                            ? simpleDrawerData.calculated_payment_date.Time
                            : undefined,
                        }}
                        pharmacyName={pharmacyName}
                      ></InvoicePDF>
                    );
                    pdf(pdfDoc)
                      .toBlob()
                      .then((blob) => {
                        const url = URL.createObjectURL(blob);
                        window.open(url, "_blank");
                      });
                  }}
                >
                  Print Invoice
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col px-1">
            <DrawerHeader>
              <DrawerTitle>Invoice Details</DrawerTitle>
            </DrawerHeader>
            <Row>
              <Cell>
                <Title>Payment Date</Title>
                <Content>
                  {simpleDrawerData.calculated_payment_date.Valid ? dayjs(simpleDrawerData.calculated_payment_date.Time).format(
                    "DD-MM-YYYY"
                  ) : "N/A"}
                </Content>
              </Cell>
              <Cell>
                <Title>Created Date</Title>
                <Content>
                  {dayjs(simpleDrawerData.created_at).format("DD-MM-YYYY")}
                </Content>
              </Cell>
            </Row>
            <Row>
              <Cell>
                <Title>Supplier Name</Title>
                <Content>{simpleDrawerData.supplier_name}</Content>
              </Cell>
              <Cell>
                <Title>Delivary Date</Title>
                <Content>
                  {dayjs(simpleDrawerData.delivery_date).format("DD-MM-YYYY")}
                </Content>
              </Cell>
            </Row>
            <Row>
              <Cell>
                <Title>Invoice Date</Title>
                <Content>
                  {dayjs(simpleDrawerData.invoice_date).format("DD-MM-YYYY")}
                </Content>
              </Cell>
              <Cell>
                <Title>Invoice Number</Title>
                <Content>{drawerData.invoice_number}</Content>
              </Cell>
            </Row>
            <Row>
              <Cell>
                <Title>Payment Mode</Title>
                <Content>{normalizeEnums(drawerData.payment_mode)}</Content>
              </Cell>
              <Cell>
                <Title>Payment Type</Title>
                <Content>{normalizeEnums(drawerData.payment_type)}</Content>
              </Cell>
            </Row>
          </div>
        </div>
      )}
    </DrawerContent>
  );
};

export default function Component() {
  const routeData = PurchaseInvoiceRoute.useLoaderData();
  const { invoices: data, suppliers } = routeData;
  const searchParams = PurchaseInvoiceRoute.useSearch();

  const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState<
    PurchaseInvoiceManySchema | undefined
  >();
  const [supplierName, setSupplierName] = useState<string>("");

  useEffect(() => {
    const selectedSupplier = suppliers.find(
      (s) => s.id === searchParams.supplierID
    );
    setSupplierName(selectedSupplier ? selectedSupplier.name : "");
  }, [searchParams]);

  return (
    <div className="">
      <Drawer direction="right">
        {drawerData && (
          <DrawerDataRenderer
            simpleDrawerData={drawerData}
          ></DrawerDataRenderer>
        )}
        <div>
          <div className="flex items-center justify-between">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    onClick={() => {
                      navigate({
                        to: "/invoicing",
                      });
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Reports Overview
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Purchase Invoice List {supplierName !== "" && <span> - {supplierName}</span>}</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
            {/* Make two buttons, one to select a different supplier, other to show all suppliers on the right side of the page */}
            <div className="flex gap-4">
              <Button
                size={'sm'}
                onClick={() => {
                  navigate({
                    search: {
                      ...searchParams,
                      open_supplier_picker: true,
                      supplierID: undefined,
                    },
                  });
                }}
              >
                {
                  supplierName !== "" ? `Change Supplier` : `Filter By Supplier`
                }
              </Button>
              {supplierName !== "" && <Button
                size={'sm'}
                onClick={() => {
                  navigate({
                    search: {
                      ...searchParams,
                      open_supplier_picker: false,
                      supplierID: undefined,
                    },
                  });
                }}
              >
                Show All Suppliers
              </Button>}
            </div>
          </div>
          <hr className="my-4" />
          <Table className="mt-4">
            <TableHeader>
              <TableRow className="hover:bg-inherit">
                <TableHead>Invoice No.</TableHead>
                <TableHead>Date</TableHead>
                <TableHead>Supplier Name</TableHead>
                <TableHead>Created Date</TableHead>
                <TableHead>Payment Mode</TableHead>
                <TableHead>Payment Type</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((item, index) => {
                return (
                  // @ts-ignore
                  <DrawerTrigger key={index} type="" asChild className="w-full">
                    <TableRow
                      className="font-medium cursor-pointer"
                      onClick={() => {
                        setDrawerData(item);
                      }}
                    >
                      <TableCell>{item.invoice_number}</TableCell>
                      <TableCell>
                        {dayjs(item.invoice_date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{item.supplier_name}</TableCell>
                      <TableCell>
                        {dayjs(item.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{normalizeEnums(item.payment_mode)}</TableCell>
                      <TableCell>{normalizeEnums(item.payment_type)}</TableCell>
                    </TableRow>
                  </DrawerTrigger>
                );
              })}
            </TableBody>
          </Table>
          <div className="flex items-center justify-center w-full fixed bottom-6">
            <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              Page {searchParams.page} of{" "}
              {data.length > 0
                ? Math.ceil(data[0].full_count / searchParams.pageSize)
                : 1}
            </div>
            <div className="flex space-x-2">
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => {
                  navigate({
                    search: {
                      ...searchParams,
                      page: 1,
                    },
                  });
                }}
                disabled={searchParams.page === 1}
              >
                <span className="sr-only">Go to first page</span>
                <DoubleArrowLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() => {
                  navigate({
                    search: {
                      ...searchParams,
                      page: searchParams.page - 1,
                    },
                  });
                }}
                disabled={searchParams.page === 1}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() => {
                  navigate({
                    search: {
                      ...searchParams,
                      page: searchParams.page + 1,
                    },
                  });
                }}
                disabled={
                  data.length === 0 ||
                  data[0].full_count <=
                    searchParams.page * searchParams.pageSize
                }
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => {
                  navigate({
                    search: {
                      ...searchParams,
                      page: Math.ceil(
                        data[0].full_count / searchParams.pageSize
                      ),
                    },
                  });
                }}
                disabled={
                  data.length === 0 ||
                  data[0].full_count <=
                    searchParams.page * searchParams.pageSize
                }
              >
                <span className="sr-only">Go to last page</span>
                <DoubleArrowRightIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <Dialog open={searchParams.open_supplier_picker ?? false}>
        <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Select a supplier</DialogTitle>
                <DialogDescription>
                  Select a supplier to filter the purchase invoices
                </DialogDescription>
                <Button
                    variant={'ghost'} 
                    className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                    onClick={() => {
                      navigate({
                        to: "/reports/purchase-invoice-list/",
                        search: {
                          ...searchParams,
                          open_supplier_picker: false,
                        }
                      })
                    }}
                >
                    <X className="h-4 w-4" />
                    <span className="sr-only">Close</span>
                </Button>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                {/* Make a table list to select from */}
                <Table className="text-sm">
                  <TableHeader>
                    <TableRow>
                      <TableHead>Supplier Name</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {suppliers.map((supplier, index) => {
                      return (
                        <TableRow key={index} className="cursor-pointer" onClick={() => {
                          navigate({
                            to: "/reports/purchase-invoice-list/",
                            search: {
                              ...searchParams,
                              supplierID: supplier.id,
                              open_supplier_picker: false,
                            }
                          })
                        }}>
                          <TableCell>{supplier.name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
