import React from "react";
import { InvoicingOverview } from "./data/schema";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";

const OverviewCard = ({
  title,
  value,
  extra,
  color,
}: {
  title: string;
  value: string;
  extra?: string;
  color?: string;
}) => {
  return (
    <div className="border-[2px] rounded-lg py-4 px-6">
      <p className="font-medium">{title}</p>
      <p className="font-semibold text-2xl whitespace-pre">
        {value}
        <br />
        <span
          className={`text-${color ? color : "gray"}-500 text-sm font-normal`}
        >
          {extra}
        </span>
      </p>
    </div>
  );
};

const Overview = ({ data }: { data: InvoicingOverview }) => {
  return (
    <div className="w-full grid grid-cols-4 space-x-6">
      <OverviewCard
        title="Total Sales"
        value={convertToCurrencyRounded(
          data.total_sales.request_month_sales.total_sales
        )}
        color={
          data.total_sales.request_month_sales.total_sales ===
          data.total_sales.last_month_sales.total_sales
            ? "gray"
            : data.total_sales.request_month_sales.total_sales >
                data.total_sales.last_month_sales.total_sales
              ? "green"
              : "red"
        }
        extra={
          data.total_sales.request_month_sales.total_sales ===
          data.total_sales.last_month_sales.total_sales
            ? "No change from last month"
            : data.total_sales.request_month_sales.total_sales >
                data.total_sales.last_month_sales.total_sales
              ? ((data.total_sales.request_month_sales.total_sales -
                  data.total_sales.last_month_sales.total_sales) /
                  data.total_sales.last_month_sales.total_sales) *
                  100 >
                1000
                ? "1000%+ from last year"
                : `+ ${(
                    ((data.total_sales.request_month_sales.total_sales -
                      data.total_sales.last_month_sales.total_sales) /
                      data.total_sales.last_month_sales.total_sales) *
                    100
                  ).toFixed(2)}% from last month`
              : `- ${(
                  ((data.total_sales.last_month_sales.total_sales -
                    data.total_sales.request_month_sales.total_sales) /
                    data.total_sales.last_month_sales.total_sales) *
                  100
                ).toFixed(2)}% from last month`
        }
      />
      <OverviewCard
        title="Insurance Sales"
        value={convertToCurrencyRounded(
          data.total_insurance.request_month_sales.total_sales
        )}
        extra={
          data.total_insurance.request_month_sales.total_sales ===
          data.total_insurance.last_month_sales.total_sales
            ? "No change from last month"
            : data.total_insurance.request_month_sales.total_sales >
                data.total_insurance.last_month_sales.total_sales
              ? ((data.total_insurance.request_month_sales.total_sales -
                  data.total_insurance.last_month_sales.total_sales) /
                  data.total_insurance.last_month_sales.total_sales) *
                  100 >
                1000
                ? "1000%+ from last year"
                : `+ ${(
                    ((data.total_insurance.request_month_sales.total_sales -
                      data.total_insurance.last_month_sales.total_sales) /
                      data.total_insurance.last_month_sales.total_sales) *
                    100
                  ).toFixed(2)}% from last month`
              : `- ${(
                  ((data.total_insurance.last_month_sales.total_sales -
                    data.total_insurance.request_month_sales.total_sales) /
                    data.total_insurance.last_month_sales.total_sales) *
                  100
                ).toFixed(2)}% from last month`
        }
        color={
          data.total_insurance.request_month_sales.total_sales ===
          data.total_insurance.last_month_sales.total_sales
            ? "gray"
            : data.total_insurance.request_month_sales.total_sales >
                data.total_insurance.last_month_sales.total_sales
              ? "green"
              : "red"
        }
      />
      <OverviewCard
        title="Refunded Transactions"
        value={convertToCurrencyRounded(
          data.total_refunds.request_month_sales.total_sales
        )}
        extra={
          data.total_refunds.request_month_sales.total_sales ===
          data.total_refunds.last_month_sales.total_sales
            ? "No change from last month"
            : data.total_refunds.request_month_sales.total_sales >
                data.total_refunds.last_month_sales.total_sales
              ? ((data.total_refunds.request_month_sales.total_sales -
                  data.total_refunds.last_month_sales.total_sales) /
                  data.total_refunds.last_month_sales.total_sales) *
                  100 >
                1000
                ? "1000%+ from last year"
                : `+ ${(
                    ((data.total_refunds.request_month_sales.total_sales -
                      data.total_refunds.last_month_sales.total_sales) /
                      data.total_refunds.last_month_sales.total_sales) *
                    100
                  ).toFixed(2)}% from last month`
              : `- ${(
                  ((data.total_refunds.last_month_sales.total_sales -
                    data.total_refunds.request_month_sales.total_sales) /
                    data.total_refunds.last_month_sales.total_sales) *
                  100
                ).toFixed(2)}% from last month`
        }
        color={
          data.total_refunds.request_month_sales.total_sales ===
          data.total_refunds.last_month_sales.total_sales
            ? "gray"
            : data.total_refunds.request_month_sales.total_sales >
                data.total_refunds.last_month_sales.total_sales
              ? "green"
              : "red"
        }
      />
      <OverviewCard
        title="Next Supplier Payment"
        value={
          data.next_supplier_payment.supplier_name !== ""
            ? dayjs(data.next_supplier_payment.next_payment_date).format(
                "DD MMM YYYY"
              )
            : "Not Available"
        }
        extra={
          data.next_supplier_payment.supplier_name !== ""
            ? data.next_supplier_payment.supplier_name
            : "Not Available"
        }
      />
    </div>
  );
};

export default Overview;
