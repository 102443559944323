import {z} from 'zod';

export const SearchParams = z.object({
    page: z.number().default(1),
    pageSize: z.number().default(10),
    search: z.string().optional(),
    expiring_in_30_days: z.boolean().default(false),
    expired: z.boolean().default(false),
    type: z.enum(["MEDICAL", "NON_MEDICAL"]).default("MEDICAL"),
});

export const SearchParamsWithYear = SearchParams.extend({
    year: z.number().default(new Date().getFullYear())
});

export type SearchParams = z.infer<typeof SearchParams>;
export type SearchParamsWithYear = z.infer<typeof SearchParamsWithYear>;