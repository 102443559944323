import { Button } from '@/components/ui/button'
import { PrinterIcon, ReceiptTextIcon } from 'lucide-react'
import { Route as receiptRouter } from '@/routes/receipt/$saleId'
import parsePhoneNumber from 'libphonenumber-js'
import dayjs from 'dayjs'
import { convertToCurrencyRounded } from '@/utils/currency_converter'
import { useNavigate } from '@tanstack/react-router'
import { usePrint } from '@/layouts/layout/component'
import { useCallback, useEffect, useRef } from 'react'

const Receipt = () => {
    const saleData = receiptRouter.useLoaderData()
    const searchParams = receiptRouter.useSearch()
    const navigate = useNavigate()
    const print = usePrint()
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (print.printMode) {
            window.print()
            print.setPrintMode(false)
        }
    }, [print.printMode])

    return (
        <div className={`${print.printMode ? "" : 'overflow-y-scroll h-screen pb-48'}`} ref={containerRef}>
            <div className="flex flex-col w-full items-center justify-center text-center overflow-auto py-4 mx-auto max-w-[700px] px-1">
                <div className="w-full">
                    <h1 className="text-2xl font-semibold">
                        {saleData.pharmacy_info.metadata.pharmacy_name}
                    </h1>
                    {/* <p className='text-sm text-gray-500'>Wed, April 27, 2023 • 9:27:53 AM</p> */}
                    <p className="text-sm text-gray-500">
                        {dayjs(saleData.created_at).format(`${saleData.pharmacy_info.settings.invoice_date_format} | ${saleData.pharmacy_info.settings.invoice_time_format}`)}
                    </p>
                    <p className="text-sm text-gray-500">
                        {/* TRN: {pharmacyInfo.pharmacyTRN} */}
                        TRN: {saleData.pharmacy_info.metadata.trn}
                    </p>
                    <p className="text-sm text-gray-500">
                        {/* Phone Number: {pharmacyInfo.pharmacyPhone} */}
                        Phone Number: {parsePhoneNumber(saleData.pharmacy_info.metadata.phone_number, "AE")!.formatInternational()}
                    </p>
                    <p className="text-sm text-gray-500">
                        {/* Address: {pharmacyInfo.pharmacyAddress} */}
                        Address: {saleData.pharmacy_info.location.address}
                    </p>
                    <div className="border-dashed border-2 border-gray-500 rounded-lg p-4 text-center relative mt-8">
                        <span className="absolute -top-4 left-0 right-0 mx-auto text-gray-500 text-xs font-bold uppercase tracking-wide w-full grid place-items-center">
                            <p className="bg-white z-10 px-4 text-lg">Invoice No</p>
                        </span>
                        <div className="">
                            <p className="font-semibold">
                                {saleData.invoice_id}
                            </p>
                        </div>
                    </div>

                    {saleData.is_valid_insurance ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Insurance Used</p>
                        <p>Yes</p>
                    </div> : null}
                    {saleData.is_valid_insurance ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Insurance Name</p>
                        <p>{saleData.insurance_details.insurance_name}</p>
                    </div> : null}
                    <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Payment Method</p>
                        <p>{saleData.sale_payment_type}</p>
                    </div>

                    {saleData.sale_status.refunded || saleData.sale_status.void ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Payment Status</p>
                        <p>{saleData.sale_status.refunded ? saleData.sale_status.void ? 'VOID' : 'REFUNDED' : ""}</p>
                    </div> : null}

                    <hr className="my-4" />

                    {saleData.is_valid_patient ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Customer Name</p>
                        {saleData.patient_info.name}
                    </div> : null}

                    <div className="flex items-center justify-between text-sm mt-2">
                        <p className="text-gray-500">Customer Type</p>
                        <p>{saleData.is_valid_patient ? "Registered" : "OTC"}</p>
                    </div>

                    {saleData.is_valid_patient ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Customer Phone</p>
                        {parsePhoneNumber(saleData.patient_info.phone, "AE")!.formatInternational()}
                    </div> : null}

                    <hr className="my-4" />
                    {saleData.medical_items.map((item, index) => (
                        <div key={index} className="flex items-center justify-between text-sm mt-2">
                            <p className="text-gray-500 text-left">
                                {item.trade_name} {item.unit_type}
                                <br />
                                <span className="text-xs">
                                    {item.batch_number}//
                                    {dayjs(item.expiry_date).format("DD-MM-YYYY")}
                                </span>
                            </p>
                            <div className='text-right'>
                                <p className='text-gray-500 text-xs'>x{item.sale_quantity}</p>
                                <p>{convertToCurrencyRounded(item.price * item.sale_quantity)}</p>
                            </div>
                        </div>
                    ))}
                    {saleData.non_medical_items.map((item, index) => (
                        <div key={index} className="flex items-center justify-between text-sm mt-2">
                            <p className="text-gray-500 text-left">
                                {item.trade_name} {item.unit_type}
                                <br />
                                <span className="text-xs">
                                    {item.batch_number}<span className='px-1'>&bull;</span>
                                    {dayjs(item.expiry_date.time).format("DD-MM-YYYY")}<span className='px-1'>&bull;</span>
                                    {convertToCurrencyRounded(item.price)}
                                </span>
                            </p>
                            <span className='text-right'>
                                <p className='text-gray-500 text-xs'>x{item.sale_quantity}</p>
                                <p>{convertToCurrencyRounded(item.price * item.sale_quantity)}</p>
                            </span>
                        </div>
                    ))}
                    <hr className="my-4" />

                    <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Total Items</p>
                        {/* <p>{salesItems.length}</p> */}
                        <p>{saleData.medical_items.length + saleData.non_medical_items.length}</p>
                    </div>

                    <hr className="my-4" />

                    <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Amount</p>
                        <p>{convertToCurrencyRounded(saleData.sale_amounts.card + saleData.sale_amounts.cash + saleData.sale_amounts.total_discount - saleData.sale_amounts.vat)}</p>
                    </div>
                    <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Discount</p>
                        <p>{convertToCurrencyRounded(saleData.sale_amounts.total_discount)}</p>
                    </div>
                    {saleData.is_valid_insurance ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Insurance</p>
                        <p>{convertToCurrencyRounded(saleData.sale_amounts.insurance_amount)}</p>
                    </div> : null}

                    <div className="flex items-center justify-between text-sm mt-2">
                        <p className="text-gray-500">VAT</p>
                        <p>{convertToCurrencyRounded(saleData.sale_amounts.vat)}</p>
                    </div>

                    <div className="flex items-center justify-between text-sm mt-2">
                        <p className="text-gray-500">Total Paid</p>
                        <p>{convertToCurrencyRounded(saleData.sale_amounts.card + saleData.sale_amounts.cash + saleData.sale_amounts.insurance_amount)}</p>
                    </div>

                    <hr className="my-4" />

                    {saleData.sale_payment_type === "CASH" || saleData.sale_payment_type === "SPLIT" ? <>
                        <div className="flex items-center justify-between text-sm mt-4">
                            <p className="text-gray-500">Cash Received</p>
                            <p>{convertToCurrencyRounded(saleData.sale_amounts.cash_received)}</p>
                        </div>
                        <div className="flex items-center justify-between text-sm mt-4">
                            <p className="text-gray-500">Balance</p>
                            <p>{convertToCurrencyRounded(saleData.sale_amounts.cash_received - saleData.sale_amounts.cash)}</p>
                        </div>
                    </> : null}
                    {saleData.sale_payment_type === "SPLIT" ? <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Card Amount</p>
                        <p>{convertToCurrencyRounded(saleData.sale_amounts.card)}</p>
                    </div> : null}
                    <div className="flex items-center justify-between text-sm mt-4">
                        <p className="text-gray-500">Pharmacist</p>
                        <p>{saleData.user_info.first_name} {saleData.user_info.last_name}</p>
                    </div>

                    <div className='flex flex-col space-y-4 mt-8 print:hidden'>
                        <Button
                            className='bg-indigo-600 rounded-full'
                            onClick={() => {
                                navigate({
                                    to: searchParams.return_to === "sales_history" ? "/overview/sales_history" : searchParams.return_to === "sales_history_one" ? "/overview/sales_history/" + saleData.sale_id : "/sales",
                                })
                            }}
                        >
                            {searchParams.return_to === "sales_history" ? "Back to Sales History" : searchParams.return_to === "sales_history_one" ? "Back to Sale Info" : "Back to Sales"}
                        </Button>

                        <Button
                            variant={'outline'}
                            className='rounded-full'
                        >
                            <ReceiptTextIcon className='w-4 h-4 mr-2' />
                            Send E-receipt
                        </Button>

                        <Button
                            variant={'outline'}
                            className='rounded-full'
                            onClick={() => {
                                print.setPrintMode(true)
                            }}
                        >
                            <PrinterIcon className='w-4 h-4 mr-2' />
                            Print PDF Receipt
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Receipt