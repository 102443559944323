import { createFileRoute, notFound } from "@tanstack/react-router";
import Component from "@/screens/inventory/item/component";
import { SearchParams, SearchParamsWithYear } from "@/screens/inventory/available-inventory/model";
import { getMedicalItemDetails, getMedicalItemInventory, getMedicalItemPricingHistoryMaster, getMedicalItemPricingHistoryPharmacy, getMedicalMonthlyStockReport, getNonMedicalItemDetails, getNonMedicalItemInventory, getNonMedicalMonthlyStockReport } from "@/screens/inventory/item/api";
import { CombinedItemOneData, CombinedItemSchema, MedicalPricingHistoryMaster, MedicalPricingHistoryPharmacy, MonthlyStockReportSchema } from "@/screens/inventory/item/model";
import { Loading } from "@/layouts/loading";
import { z } from "zod";

type Props = {
    inventoryData: CombinedItemSchema,
    itemData: CombinedItemOneData,
    pricingHistoryMaster: MedicalPricingHistoryMaster[],
    pricingHistoryPharmacy: MedicalPricingHistoryPharmacy[],
    monthlyStockReport: MonthlyStockReportSchema[]
}

export const Route = createFileRoute("/inventory/item/$type/$itemId")({
    validateSearch: SearchParamsWithYear,
    loaderDeps: ({ search: { year } }) => ({ year }),
    loader: async ({ params, deps }): Promise<Props> => {
        if (params.type !== "MEDICAL" && params.type !== "NON_MEDICAL") {
            throw notFound();
        }
        if (!params.itemId) {
            throw notFound();
        }
        if (params.type === "MEDICAL") {
            const inventory_data_promise = getMedicalItemInventory(params.itemId);
            const item_data_promise = getMedicalItemDetails(params.itemId);
            const pricing_history_master_promise = getMedicalItemPricingHistoryMaster(params.itemId);
            const pricing_history_pharmacy_promise = getMedicalItemPricingHistoryPharmacy(params.itemId);
            const monthly_stock_report_promise = getMedicalMonthlyStockReport(params.itemId, deps.year);
            const [inventory_data, item_data, pricing_history_master, pricing_history_pharmacy, monthly_stock_report] = await Promise.all([inventory_data_promise, item_data_promise, pricing_history_master_promise, pricing_history_pharmacy_promise, monthly_stock_report_promise]);
            return {
                inventoryData: {
                    type: 'MEDICAL',
                    inventory_data: inventory_data
                },
                itemData: {
                    type: 'MEDICAL',
                    item_data: item_data
                },
                pricingHistoryMaster: pricing_history_master,
                pricingHistoryPharmacy: pricing_history_pharmacy,
                monthlyStockReport: monthly_stock_report
            }
        } else {
            const inventory_data_promise = getNonMedicalItemInventory(params.itemId);
            const item_data_promise = getNonMedicalItemDetails(params.itemId);
            const monthly_stock_report_promise = getNonMedicalMonthlyStockReport(params.itemId, deps.year);
            const [inventory_data, item_data, monthly_stock_report] = await Promise.all([inventory_data_promise, item_data_promise, monthly_stock_report_promise]);
            return {
                inventoryData: {
                    type: 'NON_MEDICAL',
                    inventory_data: inventory_data
                },
                itemData: {
                    type: 'NON_MEDICAL',
                    item_data: item_data
                },
                pricingHistoryMaster: [],
                pricingHistoryPharmacy: [],
                monthlyStockReport: monthly_stock_report
            }
        }
    },
    component: Component,
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});