import { httpClient } from "@/httpClient";
import { getMedicalItem, getNonMedicalItem } from "@/screens/sales/api";
import { MedicalInventoryItemData, MedicalItemAddSchema, MedicalItemOne, MedicalPricingHistoryMaster, MedicalPricingHistoryPharmacy, MonthlyStockReportSchema, NonMedicalInventoryItemData, NonMedicalInventoryPriceHistory, NonMedicalItemAddSchema, NonMedicalItemCreateSchema, NonMedicalItemOne } from "./model";
import getPharmacyID from "@/utils/pharmacy_code";
import { AddedItemsSchema } from "../add-stock/schema";
import dayjs from "dayjs";

export async function getMedicalItemInventory(itemId: string) {
    return await getMedicalItem(itemId, false)
}

export async function getNonMedicalItemInventory(itemId: string) {
    return await getNonMedicalItem(itemId)
}

export async function getMedicalItemDetails(itemId: string) {
    const response = await httpClient.get(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/get/one`, {
        params: {
            medicalMasterId: itemId
        }
    })
    return response.data.data as MedicalItemOne
}
export async function getNonMedicalItemDetails(itemId: string) {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/non_medical_master/get/one`, {
        id: itemId
    })
    return response.data.data as NonMedicalItemOne
}

export async function getMedicalItemPricingHistoryMaster(itemId: string) {
    const response = await httpClient.get(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/get/price_history/master`, {
        params: {
            medicalMasterId: itemId
        }
    })
    return response.data.data as MedicalPricingHistoryMaster[]
}

export async function getMedicalItemPricingHistoryPharmacy(itemId: string) {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/get/price_history/pharmacy?medicalMasterId=${itemId}`, {
        pharmacy_code: getPharmacyID()
    })
    return response.data.data as MedicalPricingHistoryPharmacy[]
}

export async function acceptMedicalPendingPriceIndividual(medicalHistoryId: string) {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/pricing/accept/individual`, {
        pharmacy_code: getPharmacyID(),
        medical_history_id: medicalHistoryId
    })
    return response.data.status as boolean
}

export const getMedicalItemPrice = async (medicalMasterId: string): Promise<number> => {
    const response = await httpClient.get(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/medical_master/get/current_active_price?medicalMasterId=${medicalMasterId}&pharmacyCode=${getPharmacyID()}`);
    return response.data.data as number;
}

export const submitInventory = async (data: AddedItemsSchema[]): Promise<void> => {
    let medical_items: MedicalItemAddSchema[] = []
    let non_medical_items: NonMedicalItemAddSchema[] = []
    data.forEach((item) => {
        if (item.type === "MEDICAL") {
            medical_items.push({
                batch_number: item.batch_number,
                cost: item.cost,
                expiry_date: dayjs(item.expiry_date).format("YYYY-MM-DD"),
                pharmacy_code: getPharmacyID(),
                production_date: item.production_date ? dayjs(item.production_date).format("YYYY-MM-DD") : "",
                quantity: {
                    bonus: item.quantity.bonus,
                    original: item.quantity.original,
                },
                medical_master_id: item.medical_master_id!,
                metadata: {
                    custom_barcode: item.metadata.custom_barcode || "",
                    maximum_discount_type: item.metadata.discount_type || "NONE",
                    maximum_discount_value: item.metadata.discount_value || 0,
                }
            })
        } else if (item.type === "NON_MEDICAL") {
            non_medical_items.push({
                batch_number: item.batch_number,
                cost: item.cost,
                expiry_date: dayjs(item.expiry_date).format("YYYY-MM-DD"),
                price: item.price || 0,
                production_date: item.production_date ? dayjs(item.production_date).format("YYYY-MM-DD") : "",
                quantity: {
                    bonus: item.quantity.bonus,
                    original: item.quantity.original,
                },
                non_medical_master_id: item.non_medical_master_id!,
                metadata: {
                    custom_barcode: item.metadata.custom_barcode || "",
                    maximum_discount_type: item.metadata.discount_type || "NONE",
                    maximum_discount_value: item.metadata.discount_value || 0,
                }
            })
        }
    })
    return httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/combined/create`, {
        medical_items,
        non_medical_items,
    })
}

export async function createNonMedicalItem(data: NonMedicalItemCreateSchema): Promise<void> {
    await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/non_medical_master/create`, {
        ...data,
        pharmacy_code: getPharmacyID()
    })
}

export async function getMedicalInventoryItemDetails(id: string): Promise<MedicalInventoryItemData> {
    const res = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/get/one/id`, {
        uuid: id
    })
    return res.data.data as MedicalInventoryItemData
}
export async function getNonMedicalInventoryItemDetails(id: string): Promise<NonMedicalInventoryItemData> {
    const res = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/get/one/id`, {
        uuid: id
    })
    return res.data.data as NonMedicalInventoryItemData
}

export async function updateMedicalInventoryItem(data: MedicalInventoryItemData): Promise<void> {
    await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/update`, {
        ...data
    })
}

export async function updateNonMedicalInventoryItem(data: NonMedicalInventoryItemData): Promise<void> {
    await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/update`, {
        ...data
    })
}

export async function getNonMedicalInventoryItemPriceHistory(id: string): Promise<NonMedicalInventoryPriceHistory[]> {
    const res = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/get/price_history`, {
        non_medical_inventory_id: id
    })
    return res.data.data as NonMedicalInventoryPriceHistory[]
}

export async function createNewPriceForNonMedicalItem(price: number, id: string): Promise<void> {
    await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/create/price_history`, {
        non_medical_inventory_id: id,
        price: price,
        effective_from: dayjs().add(1, 'days').format("YYYY-MM-DD")
    })
}

export async function getMedicalMonthlyStockReport(medical_master_id: string, year: number): Promise<MonthlyStockReportSchema[]> {
    const res = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/monthly_stock_report`, {
        medical_master_id: medical_master_id,
        pharmacy_code: getPharmacyID(),
        year: year
    })
    return res.data.data as MonthlyStockReportSchema[]
}

export async function getNonMedicalMonthlyStockReport(non_medical_master_id: string, year: number): Promise<MonthlyStockReportSchema[]> {
    const res = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/monthly_stock_report`, {
        non_medical_master_id: non_medical_master_id,
        year: year
    })
    return res.data.data as MonthlyStockReportSchema[]
}